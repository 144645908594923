import React from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import { Typography } from "decentraland-ui2"

import { SectionViewedTrack } from "../../modules/segment"
import {
  DownloadSuccessCard,
  DownloadSuccessCardContent,
  DownloadSuccessCardMedia,
  DownloadSuccessCardSubtitle,
  DownloadSuccessCardTitle,
  DownloadSuccessCardWrapper,
  DownloadSuccessFooterContainer,
  DownloadSuccessHeaderContainer,
  DownloadSuccessOsIcon,
  DownloadSuccessPageContainer,
  DownloadSuccessSubtitle,
  DownloadSuccessTitle,
} from "./DownloadSuccessLayout.styled"
import { DownloadSuccessLayoutProps } from "./DownloadSuccessLayout.types"

const DownloadSuccessLayout = React.memo(
  (props: DownloadSuccessLayoutProps) => {
    const { osIcon, osLink, steps, productAction, footerLinkLabel } = props
    const l = useFormatMessage()
    const onClickHandle = useTrackLinkContext()

    return (
      <DownloadSuccessPageContainer>
        <DownloadSuccessHeaderContainer>
          <DownloadSuccessOsIcon src={osIcon} />
          <DownloadSuccessTitle variant="h3">
            {l("page.download.success.title")}
          </DownloadSuccessTitle>
          <DownloadSuccessSubtitle variant="h5">
            {l("page.download.success.subtitle", {
              action: productAction,
            })}
          </DownloadSuccessSubtitle>
        </DownloadSuccessHeaderContainer>
        <DownloadSuccessCardWrapper>
          {steps.map((step, index) => (
            <DownloadSuccessCard>
              <DownloadSuccessCardContent>
                <Typography variant="overline">
                  {l("page.download.success.step")} {index + 1}
                </Typography>
                <DownloadSuccessCardTitle variant="h4">
                  {step.title}
                </DownloadSuccessCardTitle>
                <DownloadSuccessCardSubtitle variant="body1">
                  {step.text}
                </DownloadSuccessCardSubtitle>
              </DownloadSuccessCardContent>
              <DownloadSuccessCardMedia
                image={step.image}
              ></DownloadSuccessCardMedia>
            </DownloadSuccessCard>
          ))}
        </DownloadSuccessCardWrapper>
        <DownloadSuccessFooterContainer>
          <Typography variant="body1">
            {l("page.download.success.footer", {
              link: (
                <a
                  href={osLink}
                  onClick={onClickHandle}
                  data-event={SectionViewedTrack.Download}
                >
                  {footerLinkLabel}
                </a>
              ),
            })}
          </Typography>
        </DownloadSuccessFooterContainer>
      </DownloadSuccessPageContainer>
    )
  }
)

export { DownloadSuccessLayout }
