import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  styled,
  useTheme,
} from "decentraland-ui2"

const DownloadSuccessPageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  background: "rgb(131, 14, 91)",
  backgroundImage:
    "linear-gradient(332deg, rgba(131, 14, 91, 1) 3%, rgba(46, 1, 62, 1) 35%)",
  flex: 1,
  minHeight: "900px",
  alignItems: "center",
  justifyContent: "center",
})

const DownloadSuccessHeaderContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
})

const DownloadSuccessOsIcon = styled("img")({
  width: "40px",
  height: "40px",
  filter: "brightness(0) invert(1)",
})

const DownloadSuccessTitle = styled(Typography)({
  marginTop: "16px",
})

const DownloadSuccessSubtitle = styled(Typography)(() => {
  const theme = useTheme()
  return {
    marginTop: "8px",
    "& span": {
      color: theme.palette.primary.main,
    },
  }
})

const DownloadSuccessCardWrapper = styled(Box)({
  display: "flex",
  margin: "40px",
  flexWrap: "nowrap",
  justifyContent: "center",
})

const DownloadSuccessCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "350px",
  flex: 1,
  height: "420px",
  gap: "0px",
  borderRadius: "24px",
  opacity: "0px",
  border: "1px solid #A09BA87A",
  backgroundColor: "#16151880",
  marginRight: "16px",
  marginBottom: "16px",
  "&:last-child": {
    marginRight: "0px",
  },
})

const DownloadSuccessCardMedia = styled(CardMedia)({
  height: "240px",
  backgroundColor: "#5122545e",
})

const DownloadSuccessCardContent = styled(CardContent)({})

const DownloadSuccessCardTitle = styled(Typography)({
  /* fontWeight: 700,
  color: "#FFFFFF", */
})

const DownloadSuccessCardSubtitle = styled(Typography)({
  marginTop: "8px",
  fontWeight: 700,
  color: "#CFCDD4",
  "& span": {
    color: "#FFFFFF",
  },
})

const DownloadSuccessFooterContainer = styled(Box)({})

export {
  DownloadSuccessPageContainer,
  DownloadSuccessHeaderContainer,
  DownloadSuccessOsIcon,
  DownloadSuccessTitle,
  DownloadSuccessSubtitle,
  DownloadSuccessCardWrapper,
  DownloadSuccessCard,
  DownloadSuccessCardMedia,
  DownloadSuccessCardContent,
  DownloadSuccessCardTitle,
  DownloadSuccessCardSubtitle,
  DownloadSuccessFooterContainer,
}
